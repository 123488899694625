import axios from '../axios';

export const login = (userData) => {
  return axios.post('/login', userData);
};

export const logout = () => {
  return axios.post('/logout');
};

export const resetPassword = (userData) => {
  return axios.post('/password/reset', userData);
};

export const sendPasswordResetLink = (userData) => {
  return axios.post('/password/email', userData);
};
