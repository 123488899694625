<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>

      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto d-flex flex-column justify-content-center align-items-center position-static">
          <b-img :src="appLogoImage" alt="logo" class="logo-login-page position-absolute" />

          <validation-observer ref="loginValidation">
            <h2 class="text-left">Panel administracyjny</h2>

            <b-form class="auth-login-form mt-2" @submit.prevent>
              <b-form-group label="Email" label-for="login-email">
                <validation-provider #default="{ errors }" name="Email" rules="required|email">
                  <b-form-input
                    id="login-email"
                    v-model="auth.email"
                    :state="errors.length > 0 ? false : null"
                    name="login-email" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <validation-provider #default="{ errors }" name="Password" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input
                      id="login-password"
                      v-model="auth.password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password" />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-link :to="{ name: 'password-reset' }"> Zapomniałeś hasła? </b-link>

              <b-button type="submit" variant="primary" class="mt-4" block @click="validationForm">
                Zaloguj się
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Auth from '@/auth';
import store from '@/store/index';
import NotificationMixin from '@/mixins/NotificationMixin';
import { login } from '../http/auth';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import { required, email } from '@validations';
import { $themeConfig } from '@themeConfig';

export default {
  mixins: [togglePasswordVisibility, NotificationMixin],

  data() {
    const { appLogoImage } = $themeConfig.app;

    return {
      auth: {
        password: '',
        email: '',
      },
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      appLogoImage,

      // validation rules
      required,
      email,
    };
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg');
        return this.sideImg;
      }
      return this.sideImg;
    },
  },

  methods: {
    login() {
      login(this.auth)
        .then(({ data }) => {
          Auth.login(data.access_token, data.user);
          this.$router.push('/');
        })
        .catch(() => {
          this.showErrorNotification('Problem z logowaniem', 'Sprawdź poprawność danych logowania.');
        });
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.login();
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.logo-login-page {
  top: 1.5rem;
  right: 4.5rem;
  width: 6.5rem;
  height: 6.5rem;
}
</style>
